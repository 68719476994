import styled from "styled-components";

const Path = styled.path`
  fill: var(--color);
`;

const SVG = styled.svg`
  height: 40px;
  width: 40px;
`;

export default function LogoSVG() {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="280"
      height="280"
      viewBox="0 0 280 280"
      fill="none"
    >
      <Path
        d="M82.3529 0C36.8707 0 0 36.8707 0 82.3529V197.647C0 243.129 36.8707 280 82.3529 280H116.408C116.402 279.888 116.399 279.775 116.399 279.661V209.649H89.4709C71.8951 209.649 57.6471 195.4 57.6471 177.825V101.447C57.6471 90.9018 66.1959 82.3529 76.7414 82.3529C87.2869 82.3529 95.8357 90.9017 95.8357 101.447V165.095C95.8357 168.61 98.6853 171.46 102.201 171.46H116.399V120.542C116.399 106.481 127.797 95.0825 141.858 95.0825C155.919 95.0825 167.317 106.481 167.317 120.542V209.649H176.864C178.622 209.649 180.047 208.224 180.047 206.466V165.095C180.047 154.55 188.595 146.001 199.141 146.001C209.686 146.001 218.235 154.55 218.235 165.095V222.378C218.235 236.439 206.837 247.837 192.776 247.837H167.317V276.479C167.317 277.722 167.079 278.91 166.647 280H197.647C243.129 280 280 243.129 280 197.647V82.3529C280 36.8707 243.129 0 197.647 0H82.3529Z"
        fill="white"
      />
    </SVG>
  );
}
