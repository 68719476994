import AES from "crypto-js/aes.js";
import Utf8 from "crypto-js/enc-utf8.js";

export default function decrypt(encrypted, encryptionKey) {
  // console.log(encrypted, encryptionKey);
  var bytes = AES.decrypt(encrypted, encryptionKey);
  var decrypted = bytes.toString(Utf8);

  return decrypted;
}
