import styled from "styled-components";
import getMonthName from "../controllers/getMonthName";
import { useContext, useEffect, useRef, useState } from "react";
import onChangeStop from "../controllers/onChangeStop";
import { serverLine } from "../controllers/serverLine";
import encrypt from "../controllers/encrypt";
import Context from "../Context";
import LoadImageAndDecrypt from "./LoadImageAndDecrypt";
import getDateWithSlash from "../controllers/getDateWithSlash";

const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const DateText = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #cebaa3;
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 10px;
`;

export default function ImagePost({ postData }) {
  const { setForm } = useContext(Context);

  return (
    <Container onClick={onClick}>
      <LoadImageAndDecrypt imageName={postData.fileName} />
      <DateText>{getDate()}</DateText>
    </Container>
  );

  function onClick() {
    setForm({
      // title: "Image",
      component: <LoadImageAndDecrypt imageName={postData.fileName} />,
    });
  }

  function getDate() {
    let date = new Date(postData.createdAt);
    return getDateWithSlash(date);
  }
}
