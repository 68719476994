import compressFile from "./compressFile";
import selectFile from "./selectFile";
import uploadFile from "./uploadFile";
import CryptoJS from "crypto-js";

export default async function compressAndUploadFile(
  fileToUpdate,
  selectedFile,
  skipCompression
) {
  if (!selectedFile) {
    let files = await selectFile({ onlyImage: true });
    // console.log("event---", files);
    if (!files) throw Error("File not selected");
    if (!files.length) throw Error("File not selected");
    selectedFile = files[0];
  }

  console.log(selectedFile);

  let compressedFile = selectedFile;
  if (!skipCompression) {
    compressedFile = await compressFile(selectedFile);
  }

  let encryptedFile = await encryptFile(compressedFile);

  let fileData = await uploadFile(encryptedFile, fileToUpdate);

  return fileData;
}

function encryptFile(file) {
  return new Promise((resolve) => {
    // return resolve(file);
    var reader = new FileReader();
    reader.onload = () => {
      var key = window.intermediateKey;
      var wordArray = CryptoJS.lib.WordArray.create(reader.result); // Convert: ArrayBuffer -> WordArray
      var encrypted = CryptoJS.AES.encrypt(wordArray, key).toString(); // Encryption: I: WordArray -> O: -> Base64 encoded string (OpenSSL-format)

      // var blob = new Blob([encrypted]); // Create blob from string
      let newFile = new File([encrypted], file.name);
      resolve(newFile);
    };
    reader.readAsArrayBuffer(file);
  });
}
