import styled from "styled-components";

import LogoSVG from "./LogoSVG";
import Link from "./Link";

const BrandContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const H1 = styled.h1`
  font-weight: 900;
  margin: 0;
  margin-top: -2px;
  font-size: 21px;
  width: auto;
  width: 100%;

  @media (min-width: 920px) {
    font-size: 21px;
  }
`;

export default function BrandContainer() {
  return (
    <Link href="/">
      <BrandContainerMain>
        <LogoSVG />
        <H1>Cactus Diary</H1>
      </BrandContainerMain>
    </Link>
  );
}
