import styled from "styled-components";

const PromptContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 38vw;
  align-items: center;

  @media (max-width: 700px) {
    width: 90vw;
  }
`;

export default PromptContainer;
