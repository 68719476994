import styled from "styled-components";

import logout from "../controllers/logout";
import { useAlert } from "react-alert";
import copy from "copy-to-clipboard";
import { useContext } from "react";
import Context from "../Context";
import CreateEncryptionKey from "./CreateEncryptionKey";
import Link from "./Link";
import SpaceAdder from "./SpaceAdder";
import getSpaceName from "../controllers/getSpaceName";
import { MdApproval, MdOutlineDarkMode } from "react-icons/md";
import { FiSun } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsBack } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";

const Box = styled.div`
  width: 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 900px) {
    display: flex;
  }
`;

const Button = styled.div`
  cursor: pointer;
  flex: 1;
  padding: 15px 15px;
  background: var(--translucent);
  border-radius: 15px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }

  @media (max-width: 700px) {
    min-width: 90vw !important;
  }
`;

const ThemeButtons = styled(Button)`
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  &:hover {
    background: var(--translucent);
    color: var(--color);
  }
`;

const ThemeSelectButton = styled.div`
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;

  ${({ highlight }) => {
    if (highlight)
      return `
    
      &:after{
        content:"✓";
        height:100%;
        color:var(--color);
        
        display:flex;
        justify-content:center;
        align-items:center;
        width:100%;
      }
    `;
  }}
`;
export default function Settings() {
  const {
    loggedInUserID,
    loggedInUser,
    setForm,
    spaceID,
    updateDarkMode,
    colorMode,
  } = useContext(Context);
  const alert = useAlert();
  const navigate = useNavigate();
  let isInsideExtension = localStorage.getItem("extension-login");

  return (
    <Box>
      {loggedInUserID ? <Button onClick={logout}>Logout</Button> : null}

      <Button
        onClick={() => {
          setForm(null);
          navigate("/change-key");
        }}
      >
        Change Encryption Key
      </Button>

      <Button onClick={openModeSelector}>
        Space: {getSpaceName({ spaceID, loggedInUser })}
      </Button>
      <ThemeButtons>{renderThemes()}</ThemeButtons>

      <Button onClick={openLink("https://arnav.upon.one")}>
        About Developer
      </Button>
      <Button onClick={openLink("https://upon.one")}>About Company</Button>
    </Box>
  );

  function renderThemes() {
    let toReturn = [];
    let themes = {
      DARK: "#000",
      LIGHT: "#ffffff",
      PINK: "#BE7584",
      // TEAL: "#51A395",
      // STARS: "#0282CD",
    };

    for (let themeName in themes) {
      let color = themes[themeName];
      toReturn.push(
        <ThemeSelectButton
          highlight={colorMode == themeName}
          onClick={() => {
            changeColorMode(themeName);
          }}
          style={{ background: color }}
        ></ThemeSelectButton>
      );
    }
    return toReturn;
  }

  function changeColorMode(newTheme) {
    updateDarkMode(newTheme);
  }

  function openModeSelector() {
    setForm({ title: "Select Space", component: <SpaceAdder /> });
  }

  function openLink(theLink) {
    return () => {
      if (isInsideExtension) {
        copy(theLink);
        alert.show("Link Copied To Clipboard");
      } else {
        window.open(theLink);
      }
    };
  }
}
