import styled from "styled-components";

import Header from "./Header";
import { useContext } from "react";
import Context from "../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  gap: 30px;
  padding-bottom: 50px;
  /* background-image: url("/decoration.png"); */
  background-size: cover;

  @media (min-width: 800px) {
    /* width: 62vw;
    border-left: 1px solid var(--translucent);
    border-right: 1px solid var(--translucent);
    overflow-y: scroll;
    height: 100vh; */
  }

  /* ${({ isInsideExtension }) => {
    if (isInsideExtension)
      return `
    transform-origin: 50% 100%;
    transform:scale(0.9);
    `;
  }} */
`;

const Decoration = styled.img`
  position: absolute;
  width: 100vw;
  object-fit: cover;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100vh;
  opacity: 0.5;

  ${({ colorMode }) => {
    if (colorMode == "DARK")
      return `
      
      opacity:1;

    `;
  }}
`;

const Main = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  gap: 50px;
  width: 62vw;
  justify-content: flex-start;

  align-items: center;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 700px) {
    width: 90vw;
  }

  ${({ isInsideExtension }) => {
    if (isInsideExtension) return `zoom:90%;`;
  }}
`;

export default function WithHeader({ children }) {
  const { loggedInUserID, colorMode } = useContext(Context);

  let disablePadding = false;

  let path = window.location.pathname;
  if (!loggedInUserID) {
    if (path == "/") return children;
    if (path == "/auth-redirect") return children;
  }

  return (
    <Container>
      <Decoration colorMode={colorMode} src="/decoration.png" />
      <Header />
      <Main
        isInsideExtension={localStorage.getItem("extension-login")}
        disablePadding={disablePadding}
      >
        {children}
      </Main>
    </Container>
  );
}
