import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import onChangeStop from "../controllers/onChangeStop";
import { serverLine } from "../controllers/serverLine";
import encrypt from "../controllers/encrypt";
import Context from "../Context";
import LoadImageAndDecrypt from "./LoadImageAndDecrypt";
import getDateWithSlash from "../controllers/getDateWithSlash";
import { FiDelete } from "react-icons/fi";
import { BsImage, BsTagFill } from "react-icons/bs";
import compressAndUploadFile from "../controllers/compressAndUploadFile";
import LoadingSection from "./LoadingSection";
import { MdDelete } from "react-icons/md";
import TagAdder from "./TagAdder";
import getTagName from "../controllers/getTagName";
import selectFile from "../controllers/selectFile";
import { useAlert } from "react-alert";

const TitleBox = styled.textarea`
  padding: 0;
  /* min-height: 25px; */
  text-transform: capitalize;
  width: 100%;
  background: transparent;

  color: var(--color);
  resize: none;
  border: none;
  font-weight: 900;
  outline: none;
  font-family: "Inter", sans-serif;
  /* font-family: "Playfair Display", serif; */
  font-size: 18px;
`;

const DescBox = styled.textarea`
  padding: 0;
  /* min-height: 25px; */
  width: 100%;
  background: transparent;

  color: var(--color);
  resize: none;
  border: none;
  font-weight: 500;
  outline: none;
  font-family: "Inter", sans-serif;
  /* font-family: "Playfair Display", serif; */
  font-size: 15px;
`;

const ContentContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  background: var(--translucent);
`;

const ClosedButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  gap: 10px;
  width: 100%;
  border-radius: 5px;
  background: var(--translucent);

  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const CollapsedText = styled.div`
  padding: 10px;
  width: 100%;
  font-size: 18px;
  font-family: "Inter";
`;

const CollapsedTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  flex-wrap: wrap;
  text-transform: capitalize;
  /* word-break: brea; */
`;

const CollapsedTitleContent = styled.span``;

const TimeContainer = styled.span`
  opacity: 0.5;
`;

const Buttons = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: row;
  gap: 15px;
  padding: 0;
`;

const Button = styled.div`
  padding: 5px 10px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubtitleBig = styled.div`
  padding: 0;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
`;

const SubtitleSmall = styled.div``;

export default function ItemBox({ index, postData, data, setData, viewType }) {
  const { loggedInUser, setForm } = useContext(Context);
  const [isOpened, setIsOpened] = useState(false);
  const descRef = useRef(null);
  const titleRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  let item = postData.content[index];

  const resizeTextArea = () => {
    setTimeout(() => {
      console.log("Setting it");
      if (titleRef.current) {
        titleRef.current.style.height = "auto";
        titleRef.current.style.height = titleRef.current.scrollHeight + "px";
      }

      if (descRef.current) {
        descRef.current.style.height = "auto";
        descRef.current.style.height = descRef.current.scrollHeight + "px";
      }
    }, 500);
  };

  useEffect(resizeTextArea, [
    item.title,
    item.description,
    isOpened,
    descRef.current,
    titleRef.current,
  ]);

  // useEffect(() => {
  //   if (postData.date == getDateWithSlash(new Date())) {
  //     setIsOpened(true);
  //   }
  // }, []);

  if (loading) return <LoadingSection />;

  let mainImage = null;

  if (item.images) {
    if (item.images[0]) {
      mainImage = item.images[0];
    }
  }

  if (viewType == "IMAGE") {
    if (!item.images) return null;
    if (!item.images[0]) return null;
  }

  let shortenedTitle = item.title;

  if (shortenedTitle.length > 50)
    shortenedTitle = shortenedTitle.substr(0, 50) + "...";

  if (viewType == "IMAGE" && !isOpened)
    return (
      <ClosedButton
        style={{ flexDirection: "column", alignItem: "flex-start" }}
        onClick={() => {
          setIsOpened(true);
        }}
      >
        {mainImage ? (
          <LoadImageAndDecrypt
            style={{ width: "100%", borderRadius: "0" }}
            imageName={mainImage}
          />
        ) : null}

        <CollapsedText>
          <CollapsedTitle>
            <CollapsedTitleContent>{shortenedTitle}</CollapsedTitleContent>
            <TimeContainer>・{getTime()}</TimeContainer>
          </CollapsedTitle>
        </CollapsedText>
      </ClosedButton>
    );

  if (!isOpened)
    return (
      <ClosedButton
        onClick={() => {
          setIsOpened(true);
        }}
      >
        {mainImage ? (
          <LoadImageAndDecrypt
            style={{ height: "70px", width: "70px", borderRadius: "0" }}
            imageName={mainImage}
          />
        ) : null}

        <CollapsedText>
          <CollapsedTitle>
            <CollapsedTitleContent>{shortenedTitle}</CollapsedTitleContent>
            <TimeContainer>・{getTime()}</TimeContainer>
          </CollapsedTitle>
        </CollapsedText>
      </ClosedButton>
    );

  return (
    <ContentContainer>
      {item.images ? renderImages() : null}

      <MainContent>
        {getSubtitle(SubtitleBig)}
        <TitleBox
          rows="1"
          value={item.title}
          ref={titleRef}
          placeholder="Type Title here"
          onChange={({ target }) => {
            let newData = { ...data };
            let prevData = newData[postData.date].content[index];
            prevData.title = target.value;
            setData(newData);
            onChangeStop({
              callback: uploadNewText,
              name: postData._id + "--" + index,
            });
          }}
        />

        <DescBox
          rows="1"
          value={item.description}
          ref={descRef}
          placeholder="Type Description here"
          onChange={({ target }) => {
            let newData = { ...data };
            let prevData = newData[postData.date].content[index];
            prevData.description = target.value;
            setData(newData);
            onChangeStop({
              callback: uploadNewText,
              name: postData._id + "--" + index,
            });
          }}
        />

        <Buttons>
          <Button onClick={deletePost}>
            <ButtonIcon>
              <MdDelete />
            </ButtonIcon>
          </Button>
          <Button onClick={addImage}>
            <BsImage />
          </Button>

          <Button onClick={openTagAdder}>
            <ButtonIcon>
              <BsTagFill />
            </ButtonIcon>
            <ButtonText>
              {getTagName({
                defaultValue: "Tag",
                prefix: "",
                loggedInUser,
                tagID: item.tagID,
              })}
            </ButtonText>
          </Button>
        </Buttons>
      </MainContent>
    </ContentContainer>
  );

  function getSubtitle(SubtitleContainer) {
    let string = `${getTime()}${getTagName({
      defaultValue: "",
      prefix: "・",
      loggedInUser,
      tagID: item.tagID,
    })}`;

    if (!string) return null;

    if (string) return <SubtitleContainer>{string}</SubtitleContainer>;
  }

  function getTime() {
    if (!item.createdAt) return "";
    let theTime = new Date(item.createdAt);
    let hrs = theTime.getHours();
    let isAm = true;
    if (hrs > 12) {
      isAm = false;
      hrs = hrs - 12;
    }
    return `${hrs}:${theTime.getMinutes()} ${isAm ? "AM" : "PM"}`;
  }

  function openTagAdder() {
    let oldTagID = null;
    let content = data[postData.date].content;
    oldTagID = content[index].tagID;

    setForm({
      title: "Select Tag",
      component: <TagAdder oldTagID={oldTagID} callback={addTag} />,
    });
  }

  function addTag(newTagID) {
    let newData = { ...data };
    let content = newData[postData.date].content;
    content[index].tagID = newTagID;
    setData(newData);
    serverLine.post("/content", {
      docID: postData._id,
      content: content,
    });
  }

  //   function getTagName(defaultValue, prefix) {
  //     if (!item.tagID) return defaultValue;

  //     if (loggedInUser.tags) {
  //       if (loggedInUser.tags[item.tagID]) {
  //         return prefix + loggedInUser.tags[item.tagID].name;
  //       }
  //     }
  //   }

  async function deleteImage(imageIndex) {
    console.log(imageIndex);
    console.log(data);
    let newData = { ...data };
    let content = newData[postData.date].content;
    if (content[index].images) {
      content[index].images.splice(imageIndex, 1);
    }
    setData(newData);
    console.log(newData);
    serverLine.post("/content", {
      docID: postData._id,
      content: content,
    });
  }

  async function addImage() {
    try {
      console.log("Selecting file");

      let files = await selectFile({ onlyImage: true });
      if (!files) return;
      if (!files[0]) return;
      setLoading(true);
      let imageData = await compressAndUploadFile(null, files[0]);
      console.log("File Selected");

      let newData = { ...data };
      let content = newData[postData.date].content;
      if (!content[index].images) {
        content[index].images = [imageData.fileName];
      } else {
        content[index].images.push(imageData.fileName);
      }
      setData(newData);
      serverLine.post("/content", {
        docID: postData._id,
        content: content,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  function renderImages() {
    let images = [];

    let imageIndex = 0;
    for (let fileName of item.images) {
      let actualIdx = imageIndex;
      console.log(actualIdx, fileName);
      images.push(
        <LoadImageAndDecrypt
          deleteButton={() => {
            deleteImage(actualIdx);
          }}
          imageName={fileName}
        />
      );
      imageIndex++;
    }

    return images;
  }

  function deletePost() {
    alert.show("Entry Deleted!");
    let newData = { ...data };
    let content = newData[postData.date].content;
    content.splice(index, 1);
    setData(newData);
    serverLine.post("/content", {
      docID: postData._id,
      content: content,
    });
  }

  function uploadNewText() {
    let content = data[postData.date].content;
    serverLine.post("/content", {
      docID: postData._id,
      content: content,
    });
  }
}
