import { useContext, useEffect, useState } from "react";
import defaultTags from "../data/defaultTags";
import Context from "../Context";
import { styled } from "styled-components";
import { GrAdd, GrSelect } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { nanoid } from "nanoid";
import { AiOutlineCheck, AiOutlineEdit } from "react-icons/ai";
import { serverLine } from "../controllers/serverLine";
import onChangeStop from "../controllers/onChangeStop";
import defaultSpaces from "../data/defaultSpaces";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const TagCreator = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
`;
const ModeInput = styled.input`
  padding: 0 15px;
  height: 40px;
  text-transform: capitalize;
  background: transparent;
  color: var(--color);
  border-radius: 10px;
  background: var(--translucent);
  border: none;
  flex: 1;
`;
const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  gap: 50px;
  align-items: center;
`;

const ModeButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const ModeName = styled.div`
  font-weight: 600;
  font-size: 21px;
`;
const ModeNameInput = styled.input``;

const RoundButton = styled(Button)`
  padding: 0;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  ${({ highlight }) => {
    if (highlight)
      return `
      background:var(--color);
      color:var(--bgColor);
    `;
  }}
`;

export default function SpaceAdder() {
  const { loggedInUser, setForm, setLoggedInUser, setSpaceID, spaceID } =
    useContext(Context);
  const [spaces, setSpaces] = useState({});
  const [newModeName, setNewModeName] = useState("");

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.spaces) {
        setSpaces({ ...loggedInUser.spaces });
      }
    }
  }, [loggedInUser]);

  let callback = (newModeID) => {
    setSpaceID(newModeID);
  };

  return (
    <Container>
      {renderModes()}
      <TagCreator>
        <ModeInput
          placeholder="Type new space name here"
          value={newModeName}
          onChange={({ target }) => {
            setNewModeName(target.value);
          }}
        />
        <Button onClick={createMode}>
          <ButtonIcon>
            <AiOutlineCheck />
          </ButtonIcon>
          <ButtonText>Create</ButtonText>
        </Button>
      </TagCreator>
    </Container>
  );

  function createMode() {
    let newModeID = nanoid();
    let newSpaces = { ...spaces };
    newSpaces[newModeID] = { name: newModeName };
    setSpaces(newSpaces);
    setNewModeName("");
    let newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.spaces = newSpaces;
    setLoggedInUser(newLoggedInUser);
    serverLine.patch("/profile", { changes: { allSpaces: newSpaces } });
  }

  function selectTag(spaceID) {
    setForm(null);
    callback(spaceID);
  }

  function renderModes() {
    let toReturn = [];

    let allSpaces = { ...defaultSpaces, ...spaces };

    for (let itsSpaceID in allSpaces) {
      let space = allSpaces[itsSpaceID];

      toReturn.push(
        <ModeBox
          selectedSpaceID={spaceID}
          spaces={spaces}
          key={itsSpaceID}
          setSpaces={setSpaces}
          spaceID={itsSpaceID}
          space={space}
          selectTag={selectTag}
        />
      );
    }

    return toReturn;
  }
}

function ModeBox({
  spaceID,
  selectedSpaceID,
  space,
  selectTag,
  spaces,
  setSpaces,
}) {
  const [editEnabled, setEditEnabled] = useState(false);
  const { loggedInUser, setLoggedInUser } = useContext(Context);

  return (
    <TagContainer>
      {editEnabled ? (
        <ModeInput
          placeholder="Edit Mode Name"
          onChange={updateModeName}
          value={space.name}
        />
      ) : (
        <ModeName>{space.name}</ModeName>
      )}

      <ModeButtons>
        <RoundButton
          highlight={spaceID == selectedSpaceID}
          onClick={() => {
            selectTag(spaceID);
          }}
        >
          <ButtonIcon>
            <AiOutlineCheck />
          </ButtonIcon>
        </RoundButton>
        {editEnabled || space.disableEdit ? null : (
          <RoundButton
            onClick={() => {
              setEditEnabled(true);
            }}
          >
            <ButtonIcon>
              <AiOutlineEdit />
            </ButtonIcon>
          </RoundButton>
        )}
      </ModeButtons>
    </TagContainer>
  );

  function updateModeName({ target }) {
    let newSpaces = { ...spaces };
    newSpaces[spaceID].name = target.value;
    setSpaces(newSpaces);
    let newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.spaces = newSpaces;
    setLoggedInUser(newLoggedInUser);
    onChangeStop({
      callback: () => {
        serverLine.patch("/profile", { changes: { allSpaces: newSpaces } });
      },
      name: spaceID,
    });
  }
}
