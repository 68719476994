import { useContext, useEffect, useState } from "react";
import defaultTags from "../data/defaultTags";
import Context from "../Context";
import { styled } from "styled-components";
import { GrAdd, GrSelect } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { nanoid } from "nanoid";
import { AiOutlineCheck, AiOutlineEdit } from "react-icons/ai";
import { serverLine } from "../controllers/serverLine";
import onChangeStop from "../controllers/onChangeStop";
import Calendar from "react-calendar";
import "../calendar.css";
// import "react-calendar/dist/Calendar.css";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;
const TagCreator = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
`;
const TagInput = styled.input`
  padding: 0 15px;
  height: 40px;
  text-transform: capitalize;
  background: transparent;
  color: var(--color);
  border-radius: 10px;
  background: var(--translucent);
  border: none;
  flex: 1;
`;
const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  gap: 50px;
  align-items: center;
`;

const TagButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const TagName = styled.div`
  font-weight: 600;
  font-size: 21px;
`;
const TagNameInput = styled.input``;

export default function DateSelector({ callback, currentDate }) {
  const { setForm } = useContext(Context);

  return (
    <Container>
      <Calendar
        onChange={onChange}
        value={currentDate ? currentDate : new Date()}
      />
    </Container>
  );

  function onChange(newDate) {
    callback(newDate);
  }
}
