import styled from "styled-components";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";

const Container = styled.span`
  font-size: 18px;
  opacity: 0.5;
  text-align: center;
`;

function PromptSubtitle({ children }) {
  return <Container>{capitalizeFirstLetter(children)}</Container>;
}
export default PromptSubtitle;
