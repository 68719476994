import { useContext, useEffect, useState } from "react";
import defaultTags from "../data/defaultTags";
import Context from "../Context";
import { styled } from "styled-components";
import { GrAdd, GrSelect } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { nanoid } from "nanoid";
import { AiOutlineCheck, AiOutlineEdit } from "react-icons/ai";
import { serverLine } from "../controllers/serverLine";
import onChangeStop from "../controllers/onChangeStop";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const TagCreator = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
`;
const TagInput = styled.input`
  padding: 0 15px;
  height: 40px;
  text-transform: capitalize;
  background: transparent;
  color: var(--color);
  border-radius: 10px;
  background: var(--translucent);
  border: none;
  flex: 1;
`;
const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const RoundButton = styled(Button)`
  padding: 0;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  ${({ highlight }) => {
    if (highlight)
      return `
      background:var(--color);
      color:var(--bgColor);
    `;
  }}
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  gap: 50px;
  align-items: center;
`;

const TagButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const TagName = styled.div`
  font-weight: 600;
  font-size: 21px;
`;
const TagNameInput = styled.input``;

export default function TagAdder({ callback, oldTagID }) {
  const { loggedInUser, setForm, setLoggedInUser } = useContext(Context);
  const [tags, setTags] = useState({});
  const [newTagName, setNewTagName] = useState("");

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.tags) {
        setTags({ ...loggedInUser.tags });
      }
    }
  }, [loggedInUser]);

  return (
    <Container>
      {renderTags()}
      <TagCreator>
        <TagInput
          placeholder="type new tag here"
          value={newTagName}
          onChange={({ target }) => {
            setNewTagName(target.value);
          }}
        />
        <Button onClick={createTag}>
          <ButtonIcon>
            <AiOutlineCheck />
          </ButtonIcon>
          <ButtonText>Create</ButtonText>
        </Button>
      </TagCreator>
    </Container>
  );

  function createTag() {
    let newTagID = nanoid();
    let newTags = { ...tags };
    newTags[newTagID] = { name: newTagName };
    setTags(newTags);
    setNewTagName("");
    let newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.tags = newTags;
    setLoggedInUser(newLoggedInUser);
    serverLine.patch("/profile", { changes: { allTags: newTags } });
  }

  function selectTag(tagID) {
    callback(tagID);
  }

  function renderTags() {
    let toReturn = [
      <TagContainer>
        <TagName>None</TagName>
        <TagButtons>
          <RoundButton
            highlight={!oldTagID}
            onClick={() => {
              selectTag(null);
            }}
          >
            <ButtonIcon>
              <AiOutlineCheck />
            </ButtonIcon>
          </RoundButton>
        </TagButtons>
      </TagContainer>,
    ];

    let allTags = { ...defaultTags, ...tags };

    for (let tagID in allTags) {
      let tag = allTags[tagID];

      toReturn.push(
        <TagBox
          oldTagID={oldTagID}
          tags={tags}
          key={tagID}
          setTags={setTags}
          tagID={tagID}
          tag={tag}
          selectTag={selectTag}
        />
      );
    }

    return toReturn;
  }
}

function TagBox({ tagID, tag, selectTag, tags, setTags, oldTagID }) {
  const [editEnabled, setEditEnabled] = useState(false);
  const { loggedInUser, setLoggedInUser } = useContext(Context);

  return (
    <TagContainer>
      {editEnabled ? (
        <TagInput
          placeholder="Edit Tag Name"
          onChange={updateTagName}
          value={tag.name}
        />
      ) : (
        <TagName>{tag.name}</TagName>
      )}

      <TagButtons>
        <RoundButton
          highlight={oldTagID == tagID}
          onClick={() => {
            selectTag(tagID);
          }}
        >
          <ButtonIcon>
            <AiOutlineCheck />
          </ButtonIcon>
        </RoundButton>
        {editEnabled || tag.disableEdit ? null : (
          <RoundButton
            onClick={() => {
              setEditEnabled(true);
            }}
          >
            <ButtonIcon>
              <AiOutlineEdit />
            </ButtonIcon>
          </RoundButton>
        )}
      </TagButtons>
    </TagContainer>
  );

  function updateTagName({ target }) {
    let newTags = { ...tags };
    newTags[tagID].name = target.value;
    setTags(newTags);
    let newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.tags = newTags;
    setLoggedInUser(newLoggedInUser);
    onChangeStop({
      callback: () => {
        serverLine.patch("/profile", { changes: { allTags: newTags } });
      },
      name: tagID,
    });
  }
}

/*
 * Edit Tags
 * Assign
 * Edit select tag in the diary post
 */
