import styled from "styled-components";

import logout from "../controllers/logout";
import { useAlert } from "react-alert";
import copy from "copy-to-clipboard";
import { useContext } from "react";
import Context from "../Context";
import CreateEncryptionKey from "./CreateEncryptionKey";

const Box = styled.div`
  width: 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
`;

const Button = styled.div`
  cursor: pointer;
  flex: 1;
  padding: 15px 15px;
  background: var(--translucent);
  border-radius: 15px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

export default function ChangeEncryptionKey() {
  return <CreateEncryptionKey changeKey={true} />;
}
