import { styled } from "styled-components";
import { Editor } from "./myLexicalEditor/LexicalEditor";
// import Editor from "./LexicalEditor1/Editor/Editor";

const Container = styled.div`
  display: flex;
  background: var(--translucent);
  padding: 15px;
  border-radius: 15px;
  flex-direction: column;
  flex: 1;
`;
const Title = styled.input`
  padding: 15px 0;
  padding-top: 0;
  border: none;
  width: 100%;
  text-align: left;
  border-bottom: 2px solid var(--color);
  font-size: 18px;
  font-weight: 800;
  background: transparent;
  outline: none;
  color: var(--color);
`;

export default function RichTextEditor({ value, onChange, docID }) {
  if (!value) value = { title: "Untitled", editorState: null };
  return (
    <Container>
      <Title
        placeholder="Type Title Here"
        value={value.title}
        onChange={onTitleChange}
      />
      <Editor
        docID={docID}
        initialEditorState={value.editorState}
        onEditorChange={onEditorChange}
      />
    </Container>
  );

  function onTitleChange({ target }) {
    let newValue = { ...value };
    newValue.title = target.value;
    onChange(newValue);
  }

  function onEditorChange(newEditorState) {
    let newValue = { ...value };
    newValue.editorState = newEditorState;
    onChange(newValue);
  }
}
