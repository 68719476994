import styled from "styled-components";

const SingleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const MultiColumn = styled.div`
  display: grid;

  gap: 35px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export default function CustomMasonry({ list, maxCol }) {
  let numberOfSplits = 1;

  if (window.innerWidth > 800) numberOfSplits = 2;
  if (window.innerWidth > 1000 && maxCol > 2) numberOfSplits = 3;
  if (window.innerWidth > 1100 && maxCol > 3) numberOfSplits = 4;

  let split = doSplit(numberOfSplits);

  let gridTemplateColumns = "";
  for (let i = 0; i < numberOfSplits; i++) {
    gridTemplateColumns += "1fr ";
  }

  let toRender = [];

  for (let item of split) {
    let subList = [];
    for (let subItem of item) {
      subList.push(subItem);
    }
    toRender.push(<Col>{subList}</Col>);
  }

  return <MultiColumn style={{ gridTemplateColumns }}>{toRender}</MultiColumn>;

  function doSplit(numberOfSplits) {
    let splits = [];

    let i = 0;
    for (let item of list) {
      let remainder = i % numberOfSplits;
      if (!splits[remainder]) splits[remainder] = [];
      splits[remainder].push(item);
      i++;
    }

    return splits;
  }
}
