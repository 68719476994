import Link from "./Link";
import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import Journal from "./tabs/Diary";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Padding = styled.div`
  padding: 25px;
  background-color: var(--translucent);
  border-radius: 5px;
  width: 100%;
`;

const BoxTitle = styled.h3`
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  opacity: 0.7;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 800px) {
    justify-content: flex-start;
    gap: 50px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 110px;
  width: 100%;
  padding: 0 0;
`;

export default function LoggedInHome() {
  const [homeData, setHomeData] = useState(null);
  let navigate = useNavigate();

  return <Journal />;
}
