import { useEffect, useReducer, useState } from "react";
import reducer from "../reducer";
import Context from "../Context";
import DynamicForm from "./DynamicForm";
import getUserPreferences from "../controllers/getUserPreferences";
import getUserID from "../controllers/getUserID";
import { RiGoogleFill, RiGoogleLine } from "react-icons/ri";
import goToAuthScreen from "../controllers/goToAuthScreen";
import { useAlert } from "react-alert";

import { useNavigate } from "react-router-dom";

import { serverLine } from "../controllers/serverLine";
import logout from "../controllers/logout";
import CreateEncryptionKey from "./CreateEncryptionKey";

import LoadingSection from "./LoadingSection";
import ProvideEncryptionKey from "./ProvideEncryptionKey";
import WithHeader from "./WithHeader";

function Boilerplate({ children }) {
  const [loggedInUserID, setLoggedInUserID] = useState(null);
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [intermediateKey, setIntermediateKey] = useState(null);
  const [formData, setFormData] = useState(false);
  const navigate = useNavigate();
  const [diaryDocs, setDiaryDocs] = useState(null);
  const [spaceID, setSpaceID] = useState("A");

  window.navigate = navigate;
  const alert = useAlert();

  window.doAlert = alert;

  const [colorMode, setColorMode] = useState("LIGHT");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, getUserPreferences());

  window.spaceID = spaceID;
  window.encryptionKey = encryptionKey;
  window.intermediateKey = intermediateKey;

  useEffect(() => {
    let userID = getUserID();

    console.log(userID);

    if (userID) {
      setLoggedInUserID(userID);
      updateLoggedInUser();
    } else {
      setLoggedInUserID(false);
    }
  }, []);

  useEffect(() => {
    removeFormQuery();
    backFeatures();

    let data = getUserPreferences();
    dispatch({ type: "NEW_STATE", value: data });

    let newStatus = localStorage.getItem("cactus-diary-color-mode");
    if (!newStatus) newStatus = "LIGHT";
    updateDarkMode(newStatus);
  }, []);

  if (loggedInUserID) {
    if (!loggedInUser) children = <LoadingSection />;

    if (loggedInUser) {
      // console.log(loggedInUser);
      if (!encryptionKey && window.location.pathname !== "/reset-account")
        children = <ProvideEncryptionKey />;
      if (!loggedInUser.hashedEncryptionKey) {
        children = <CreateEncryptionKey saveEncryptionKey={setEncryptionKey} />;
      }
    }
  }

  return (
    <Context.Provider
      value={{
        setDiaryDocs,
        diaryDocs,
        setLoggedInUser,
        promptLogin,
        loggedInUserID,
        setIntermediateKey,
        intermediateKey,
        encryptionKey,
        setSpaceID,
        spaceID,
        setEncryptionKey,
        state,
        popupAlert: alert.show,
        updateLoggedInUser: updateLoggedInUser,
        loggedInUser: loggedInUser,
        dispatch,
        updateDarkMode,
        colorMode,
        setForm: setForm,
      }}
    >
      <DynamicForm setForm={setForm} data={formData} />
      <WithHeader>{children}</WithHeader>
    </Context.Provider>
  );

  function updateDarkMode(newStatus) {
    setColorMode(newStatus);
    localStorage.setItem("cactus-diary-color-mode", newStatus);
    setColors(newStatus);
  }

  function updateLoggedInUser() {
    try {
      serverLine.get("/logged-in-user").then(setLoggedInUser);
    } catch (e) {
      alert.show(e.message);
      console.log("First Fetch Error----------");
      logout();
    }
  }

  function setColors(newVal) {
    //#011207

    let colors = {
      bgColor: "#f7e8d8",
      bgColor2: "#f7e8d8",
      color: "#171311",
      translucent: "rgba(0,0,0,0.1)",
      translucentHard: "rgba(0,0,0,0.2)",
    };

    if (newVal) {
      if (newVal === "DARK")
        colors = {
          bgColor: "#0f0e0d",
          bgColor2: "#0f0e0d",
          color: "#cec5ba",
          translucent: "rgba(255,255,255,0.05)",
          translucentHard: "rgba(255,255,255,0.2)",
        };
      if (newVal === "PINK")
        colors = {
          bgColor: "#FBDBE3",
          bgColor2: "#FBDBE3",
          color: "#ba566b",
          translucent: "rgb(184 87 107 / 44%)",
          translucentHard: "rgb(184 87 107 / 66%)",
        };
      if (newVal === "STARS")
        colors = {
          bgColor: "#041450",
          bgColor2: "#041450",
          color: "#ffffff",
          translucent: "rgba(0,0,0,0.05)",
          translucentHard: "rgba(0,0,0,0.2)",
        };
      if (newVal === "TEAL")
        colors = {
          bgColor: "#51A395",
          bgColor2: "#51A395",
          color: "#ffffff",
          translucent: "rgba(0,0,0,0.05)",
          translucentHard: "rgba(0,0,0,0.2)",
        };
    }

    document.documentElement.style.setProperty("--bgColor", colors.bgColor);
    document.documentElement.style.setProperty("--bgColor2", colors.bgColor2);
    document.documentElement.style.setProperty("--color", colors.color);

    document.documentElement.style.setProperty(
      "--translucent",
      colors.translucent
    );
    document.documentElement.style.setProperty(
      "--translucentHard",
      colors.translucentHard
    );
  }

  function promptLogin() {
    setFormData({
      title: "Please Login",
      buttons: [
        {
          icon: <RiGoogleFill />,
          name: "Login / Signup",
          onClick: goToAuthScreen,
        },
      ],
    });
  }

  function backFeatures() {
    window.addEventListener("popstate", (event) => {
      removeFormOnBack();
    });
  }

  function removeFormOnBack() {
    const formPage = getUrlQuery("formPage");
    if (!formPage) {
      setFormData(null);
    }
  }

  function getUrlQuery(field) {
    if (typeof window == "undefined") return null;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(field);
  }

  function setForm(data, noPathChange) {
    if (noPathChange) {
      return setFormData({ data, noPathChange });
    }

    let path = window.location.pathname;
    let queryObj = getUrlQueryObject();

    if (getUrlQuery("formPage") && !data) {
      closeForm();
    } else if (!getUrlQuery("formPage")) {
      queryObj.formPage = "true";

      let thePath = removeLastSlash(path) + queryObjToUrl(queryObj);

      // console.log(window.navigate);
      navigate(thePath);

      setFormData({ data, noPathChange });
    } else {
      setFormData({ data, noPathChange });
    }
  }

  function removeLastSlash(urlString) {
    if (urlString[urlString.length - 1] == "/") {
      return urlString.slice(0, urlString.length - 1);
    } else {
      return urlString;
    }
  }

  function queryObjToUrl(queryInput) {
    let newQueryUrl = "/?";
    let i = 0;
    let queryLen = Object.keys(queryInput).length;
    for (let key in queryInput) {
      if (queryInput[key]) {
        if (i == queryLen - 1) {
          newQueryUrl += `${key}=${queryInput[key]}`;
        } else {
          newQueryUrl += `${key}=${queryInput[key]}&`;
          i++;
        }
      }
    }

    return newQueryUrl;
  }

  function getUrlQueryObject() {
    let data = {};
    let raw = window.location.search.replace(/\?/gi, "").replace(/\//gi, "");
    if (!raw) return data;
    raw = raw.split("&");
    for (let itm of raw) {
      if (!itm) continue;
      itm = itm.split("=");
      if (itm.length == 2) {
        if (itm[1].trim()) {
          data[itm[0]] = itm[1];
        }
      }
    }
    return data;
  }

  function removeFormQuery() {
    //this prevents bug
    //what bug?
    //if url already has form query then
    //on setFormData query won't be pushed

    if (getUrlQuery("formPage")) {
      let path = window.location.pathname;
      let queryObj = getUrlQueryObject();
      delete queryObj.formPage;
      let newPath = "/";

      if (path === "/") {
        newPath = queryObjToUrl(queryObj);
      } else {
        newPath = path + queryObjToUrl(queryObj);
      }
      console.log(newPath);
      navigate(newPath, { replace: true });
    }
  }

  function closeForm() {
    //sometimes it takes a few seconds to push the router
    //if user presses back before then user might go 2 pages back
    if (getUrlQuery("formPage")) {
      navigate(-1);
    }
  }
}

export default Boilerplate;
