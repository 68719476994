import Link from "./Link";
import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import { serverLine } from "../controllers/serverLine";

import { useState } from "react";
import { FiBook, FiSettings, FiSun } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import {
  AiFillClockCircle,
  AiOutlineClockCircle,
  AiOutlineMenu,
  AiOutlineUser,
} from "react-icons/ai";
import { HiBell, HiOutlineBell, HiOutlineUser, HiUser } from "react-icons/hi";
import goToAuthScreen from "../controllers/goToAuthScreen";
import { GrGoogle } from "react-icons/gr";
import {
  RiHome2Fill,
  RiHome2Line,
  RiHome5Line,
  RiHomeFill,
  RiHomeLine,
  RiNotificationFill,
  RiNotificationLine,
  RiSearch2Line,
  RiSearchFill,
  RiSearchLine,
  RiSettings2Line,
  RiSettingsFill,
  RiSettingsLine,
  RiUserFill,
  RiUserLine,
} from "react-icons/ri";
import { MdMode, MdOutlineDarkMode, MdSettings } from "react-icons/md";
import LogoSVG from "./LogoSVG";
import { BsMenuApp, BsSun } from "react-icons/bs";
import { TbSettings } from "react-icons/tb";
import BrandContainer from "./BrandContainer";

import { FaUser } from "react-icons/fa";
import getSpaceName from "../controllers/getSpaceName";
import SpaceAdder from "./SpaceAdder";
import Settings from "./Settings";

const Container = styled.div`
  padding: 20px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 62vw;
  align-items: start;
  margin-top: 50px;
  gap: 50px;
  border-radius: 0;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 700px) {
    width: 90vw;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  ${({ isInsideExtension }) => {
    if (isInsideExtension) return `zoom:90%;`;
  }}
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
  gap: 0;
  padding: 0 0;
  border-radius: 0;
  border-radius: 10px;
  border: 1px solid var(--translucentHard);
`;

const LinkItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotifIndication = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: tomato;
  position: absolute;
  bottom: 3px;
  left: calc(50% - 4px);
`;

const LoginButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  color: var(--bgColor);
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 172px;
  border: 1px solid;
  padding: 10px 0;
  gap: 15px;
  background-color: var(--translucent);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--color);
  border: none;
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 200px;
  }
`;

const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 17px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  height: 37px;

  padding: 0 20px;
  position: relative;

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }

  position: relative;

  ${({ isActive }) => {
    if (isActive)
      return `

      background:var(--translucent);

    `;
  }}
`;

const Button = styled.div`
  display: flex;
  height: 37px;
  /* width: 45px; */
  font-size: 15px;
  gap: 15px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  /* background: var(--translucent); */

  /* width: 100px; */
  justify-content: center;
  /* height: 50px; */
  padding: 0 15px;
  border: 1px solid var(--translucentHard);

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }

  @media (max-width: 900px) {
    ${({ onlyDesktop }) => {
      if (onlyDesktop) return `display:none;`;
    }}
  }
`;

const MoreButton = styled(Button)``;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 700px) {
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
  }
`;

const CustomLink = styled(Link)`
  display: flex;
  flex: 1;
`;

const AddAchievement = styled.button`
  position: fixed;
  bottom: 50px;
  right: 50px;

  background: var(--translucent);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(35px);
  border: none;
  color: color;
  padding: 15px 20px;
  font-size: 18px;
  /* opacity: 0.5; */
  box-shadow: 1px 1px 20px 4px rgb(0 0 0 / 37%);
  color: var(--color);
  z-index: 555555;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const OnlyMobile = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

export default function Header() {
  const {
    loggedInUser,
    loggedInUserID,
    colorMode,
    updateDarkMode,
    setForm,
    spaceID,
    encryptionKey,
  } = useContext(Context);

  let isInsideExtension = localStorage.getItem("extension-login");

  let loginButton = (
    <LoginButton onClick={goToAuthScreen}>
      <GrGoogle />
      Login With Google
    </LoginButton>
  );

  if (!encryptionKey)
    return (
      <Container>
        <BrandContainer />
      </Container>
    );

  if (loggedInUserID) loginButton = null;

  return (
    <Container isInsideExtension={isInsideExtension}>
      {/* <Top> */}

      <OnlyMobile>
        <Link href="/">
          <BrandContainer />
        </Link>
      </OnlyMobile>

      <Buttons>
        {encryptionKey ? getLinks() : null}
        {loggedInUser
          ? [
              <Button onClick={openSettings} style={{ padding: "0 12px" }}>
                <AiOutlineMenu />
              </Button>,
            ]
          : loginButton}
      </Buttons>
      {/* </Top> */}
    </Container>
  );

  function openSettings() {
    setForm({ title: "Settings", component: <Settings /> });
  }

  function openModeSelector() {
    setForm({ title: "Select Space", component: <SpaceAdder /> });
  }

  function getLinks() {
    let data = (
      <Links>
        <LinkButton name={"Diary"} link={"/"} />
        <LinkButton name={"Notes"} link={"/notes"} />
      </Links>
    );

    return data;
  }

  function changeColorMode() {
    updateDarkMode(colorMode == "LIGHT" ? "DARK" : "LIGHT");
  }
}

function LinkButton({ link, name, nonActiveIcon, children }) {
  let isActive = window.location.pathname === link;
  return (
    <Link href={link}>
      <LinkButtonContainer isActive={isActive}>{name}</LinkButtonContainer>
    </Link>
  );
}
