import { useNavigate, useNavigation } from "react-router-dom";
import Link from "./Link";
import styled from "styled-components";

const Container = styled.button`
  padding: 10px 20px;
  background: var(--color);
  color: var(--bgColor);
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.25s ease-in;
  cursor: pointer;
  &:hover {
    transform: scale(0.9);
  }
`;
const IconContainer = styled.div``;
const TextContainer = styled.div``;

export default function CustomButton({ children, icon, onClick, href, style }) {
  let navigate = useNavigate();

  if (href) {
    onClick = () => {
      navigate(href);
    };
  }

  return (
    <Container style={style} onClick={onClick}>
      {icon ? <IconContainer>{icon}</IconContainer> : null}
      <TextContainer>{children}</TextContainer>
    </Container>
  );
}
