import defaultTags from "../data/defaultTags";

export default function getTagName({
  tagID,
  loggedInUser,
  defaultValue = "",
  prefix = "",
}) {
  if (!tagID) return defaultValue;

  let allTags = { ...defaultTags };

  if (loggedInUser.tags) {
    allTags = { ...allTags, ...loggedInUser.tags };
  }
  if (allTags[tagID]) {
    return prefix + allTags[tagID].name;
  }
}
