export default function rankDiaryPosts(theNewData) {
  theNewData.sort(function (b, a) {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateA.getTime() - dateB.getTime();
  });

  let documents = {};

  for (let item of theNewData) {
    documents[item["date"]] = {
      ...item,
    };
  }

  return documents;
}
