import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { serverLine } from "../../controllers/serverLine";
import CustomMasonry from "../CustomMasonry";
import ImagePost from "../ImagePost";
import LoadingSection from "../LoadingSection";
import { BsImage } from "react-icons/bs";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  gap: 15px;
`;

const Padding = styled.div`
  padding: 25px;
  background-color: var(--translucent);
  border-radius: 5px;
  width: 100%;
`;

const BoxTitle = styled.h3`
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  opacity: 0.7;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 800px) {
    justify-content: flex-start;
    gap: 50px;
  }
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Gallery() {
  const { encryptionKey, spaceID } = useContext(Context);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (encryptionKey) {
      serverLine.get("/images/?spaceID=" + spaceID).then((data) => {
        setData(data);
        setLoading(false);
      });
    }
  }, [encryptionKey]);

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <CustomMasonry maxCol={4} list={renderContent()} />
    </Container>
  );

  async function addImage() {
    setLoading(true);
    let image = await compressAndUploadFile();
    if (!data) data = [];
    let newData = [
      { fileName: image.fileName, createdAt: Date.now() },
      ...data,
    ];
    setData(newData);
    setLoading(false);
  }

  function renderContent() {
    let toReturn = [
      <Button onClick={addImage}>
        <ButtonIcon>
          <BsImage />
        </ButtonIcon>
        <ButtonText>Add Image</ButtonText>
      </Button>,
    ];

    if (!data) return [];

    for (let post of data) {
      toReturn.push(<ImagePost key={post._id} postData={post} />);
    }

    return toReturn;
  }
}
