async function uploadFile(file, fileToUpdate) {
  console.log(file);

  if (!file.name) throw Error("File name is null");
  let form = new FormData();

  form.append("spaceID", window.spaceID);
  if (fileToUpdate) form.append("fileToUpdate", fileToUpdate); //for replacing
  form.append("file", file, file.name); //if it was appended before the other appends then req.body will not be processed instantly

  let base = process.env.REACT_APP_SERVER;

  if (!base) base = "https://backend.upon.one";
  let endPoint = base + "/api/v1/cactus-diary/shared/upload";

  let token = localStorage.getItem("cactus-diary-user-token");

  let headerParam = {
    // withCredentials: true,
    // "Content-type": "application/json",
  };

  if (token) {
    let authorization = { authToken: token };
    if (window.encryptionKey) {
      authorization.encryptionKey = window.encryptionKey;
    }

    headerParam.authorization = JSON.stringify(authorization);
  }

  let response = await fetch(endPoint, {
    method: "POST",
    body: form,
    headers: headerParam,
  });

  let postData = await response.json();
  if (postData.error) throw Error(postData.error);
  return postData.data;
}

export default uploadFile;
