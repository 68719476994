import { forwardRef, useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";

import { useState } from "react";

import DiaryPost from "./DiaryPost";
import getDateWithSlash from "../controllers/getDateWithSlash";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";
import encrypt from "../controllers/encrypt";
import encryptAndUpload from "../controllers/uploadContent";
import PromptInput from "./prompt/PromptInput";
import CustomButton from "./CustomButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CustomMasonry from "./CustomMasonry";
import loadContent from "../controllers/loadContent";
import selectFile from "../controllers/selectFile";
import compressAndUploadFile from "../controllers/compressAndUploadFile";
import uploadContent from "../controllers/uploadContent";
import selectFileAndCheck from "../controllers/selectFileAndCheck";
import uploadFile from "../controllers/uploadFile";
import {
  BsCalendar,
  BsFillSendFill,
  BsImage,
  BsSave,
  BsSearch,
  BsSend,
  BsTag,
  BsTagFill,
} from "react-icons/bs";
import { AiOutlineFileImage, AiOutlineSave } from "react-icons/ai";
import TagAdder from "./TagAdder";
import getTagName from "../controllers/getTagName";
import getPostTextContent from "../controllers/getPostTextContent";
import defaultTags from "../data/defaultTags";
import onChangeStop from "../controllers/onChangeStop";
import DatePicker from "react-datepicker";
import DateSelector from "./DateSelector";
import getDateString from "../controllers/getDateString";
import { MdViewCompact } from "react-icons/md";
import { useAlert } from "react-alert";
import rankDiaryPosts from "../controllers/rankDiaryPosts";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  gap: 35px;
`;

const MainContent = styled.div`
  display: flex;

  flex-direction: column;
  gap: 25px;
  margin-bottom: 300px;
`;
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  background: transparent;
  padding: 0;
  border-radius: 5px;

  flex-direction: column;
  gap: 15px;
`;
const Input = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-family: roboto;
  background: transparent;
  /* font-weight: 600; */
  /* border-bottom: 2px solid var(--color); */
  padding: 15px;
  border-radius: 0;
  font-size: 18px;
  color: var(--color);

  /* background: transparent; */
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentGrid = styled.div`
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 700px) {
    justify-content: space-between;
  }
`;

const Inputs = styled.div`
  display: flex;
  /* background: var(--translucent); */
  flex-direction: column;
  border: 1px solid var(--translucentHard);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  gap: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SelectViewType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
  gap: 0;
  padding: 0 0;
  border-radius: 0;
  border-radius: 10px;
  border: 1px solid var(--translucentHard);
`;

const ViewType = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 17px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  position: relative;

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }

  position: relative;

  ${({ isActive }) => {
    if (isActive)
      return `
      background:var(--translucent);

    `;
  }}
`;

const Border = styled.div`
  height: 1px;
  opacity: 0.2;
  width: 100%;
  background: var(--color);
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FilterLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: space-between;
  }
`;

const FilterTagIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterTagText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterTag = styled.div`
  border: 1px solid var(--translucentHard);
  padding: 0 15px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;

  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }
`;

const SearchContainer = styled.div`
  /* background: var(--translucent); */
  padding: 0 15px;
  border: 1px solid var(--translucentHard);
  border-radius: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const SearchBox = styled.input`
  border: none;
  height: 40px;
  font-size: 15px;
  background: transparent;
  color: var(--color);

  @media (max-width: 900px) {
    width: 100px;
  }
`;
const SearchButton = styled.div`
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }
`;
const CurrentPost = styled.div``;
export default function WriteDiary() {
  const { loggedInUser, spaceID, diaryDocs, setDiaryDocs } =
    useContext(Context);
  const [tmpTitle, setTmpTitle] = useState("");
  const [tmpDesc, setTmpDesc] = useState("");

  const [loading, setLoading] = useState(false);
  const titleInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [tagID, selectTagID] = useState(null);
  const [selectedDate, selectDate] = useState(new Date());
  const [componentToShow, setComponentToShow] = useState(null);
  const alert = useAlert();

  let data = diaryDocs;
  let setData = setDiaryDocs;

  const resizeTextArea = () => {
    if (titleInputRef.current) {
      titleInputRef.current.style.height = "auto";
      titleInputRef.current.style.height =
        titleInputRef.current.scrollHeight + "px";
    }

    if (descriptionInputRef.current) {
      descriptionInputRef.current.style.height = "auto";
      descriptionInputRef.current.style.height =
        descriptionInputRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [tmpTitle, tmpDesc]);

  if (loading) return <LoadingSection />;

  // return JSON.stringify(postToHide);
  // console.log(data);
  let currentPost = null;

  if (data) {
    let dateSlash = getDateWithSlash(selectedDate);
    console.log(dateSlash, data);
    let currentPostData = data[dateSlash];

    if (currentPostData)
      currentPost = (
        <DiaryPost postData={currentPostData} data={data} setData={setData} />
      );
  }

  if (componentToShow) return componentToShow;

  return (
    <Container>
      {currentPost ? <CurrentPost>{currentPost}</CurrentPost> : null}

      <InputContainer>
        <Row>
          {selectedFiles ? (
            <ShowSelectedImage fileObject={selectedFiles[0]} />
          ) : null}
          <Inputs>
            <Input
              rows="1"
              ref={titleInputRef}
              style={{
                borderBottom: "1px solid var(--translucent)",
                fontSize: "18px",
                fontWeight: 900,
              }}
              placeholder="Type Title here"
              value={tmpTitle}
              onChange={({ target }) => {
                setTmpTitle(target.value);
              }}
            />
            <Input
              rows="1"
              ref={descriptionInputRef}
              placeholder="Type Description Here (optional)"
              value={tmpDesc}
              onChange={({ target }) => {
                setTmpDesc(target.value);
              }}
            />
          </Inputs>
        </Row>
        <Buttons>
          <Button onClick={openDateSelector}>
            <ButtonText>{getDateString(selectedDate)}</ButtonText>
          </Button>

          <Button onClick={openTagAdder}>
            <ButtonIcon>
              <BsTagFill />
            </ButtonIcon>
            {tagID ? (
              <ButtonText>
                {getTagName({ defaultValue: "Tag", loggedInUser, tagID })}
              </ButtonText>
            ) : null}
          </Button>
          <Button onClick={addImage}>
            <BsImage />
          </Button>
          <Button onClick={saveButton}>
            <ButtonIcon>
              <BsFillSendFill />
            </ButtonIcon>
            <ButtonText>Save</ButtonText>
          </Button>
        </Buttons>
      </InputContainer>
    </Container>
  );

  function resortObject(newData) {
    newData = { ...newData };
    let array = Object.values(newData);
    let processed = rankDiaryPosts(array);
    setData(processed);
  }

  function openDateSelector() {
    setComponentToShow(
      <DateSelector
        currentDate={selectedDate}
        callback={(newData) => {
          selectDate(newData);
          setComponentToShow(null);
        }}
      />
    );
  }

  function openTagAdder() {
    setComponentToShow(
      <TagAdder
        oldTagID={tagID}
        callback={(newTagID) => {
          selectTagID(newTagID);
          setComponentToShow(null);
        }}
      />
    );
  }

  function addImage() {
    selectFile({ onlyImage: true }).then(setSelectedFiles);
  }

  async function uploadImage() {
    if (!selectedFiles) return null;
    if (!selectedFiles.length) return null;
    setLoading(true);
    let newFile = await compressAndUploadFile(null, selectedFiles[0], true);
    setLoading(false);
    return newFile;
  }

  async function saveButton() {
    let theTitle = tmpTitle;
    if (theTitle) theTitle = theTitle.trim();
    if (!theTitle) return alert.show("Tittle is missing");
    let fileData = await uploadImage();
    let today = selectedDate;

    let newBlock = {
      title: theTitle,
      description: tmpDesc,
      createdAt: Date.now(),
    };

    if (fileData) {
      if (fileData.fileName) newBlock.images = [fileData.fileName];
    }

    if (tagID) newBlock.tagID = tagID;

    today = getDateWithSlash(today);

    let todaysDiary = data[today];

    if (todaysDiary) {
      let newData = { ...data };
      let prevContent = todaysDiary.content;
      if (!prevContent) prevContent = [];
      todaysDiary.content = [...prevContent, newBlock];
      setData(newData);
      uploadContent({
        docID: todaysDiary._id,
        content: todaysDiary.content,
      });
    } else {
      setLoading(true);

      let newPost = await serverLine.post("/content", {
        type: "DIARY",
        content: [newBlock],
        spaceID: spaceID,
        date: today,
      });
      let newData = { ...data };
      newData[today] = { _id: newPost._id, content: [newBlock], date: today };

      resortObject(newData);
      // setData(newData);

      setLoading(false);
    }

    alert.show("Entry Saved");
    setTmpTitle("");
    setTmpDesc("");
    setSelectedFiles(null);
  }
}

const SelectedImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  /* height: auto; */
  /* height: 300px; */
  border-radius: 10px;
`;

function ShowSelectedImage({ fileObject }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    var fr = new FileReader();
    fr.onload = function (e) {
      setSrc(this.result);
    };
    fr.readAsDataURL(fileObject);
  }, [fileObject]);

  if (!fileObject) return;
  if (!src) return null;
  return <SelectedImage src={src} />;
}
