import { useContext, useEffect } from "react";
import { useState } from "react";
import CryptoJS from "crypto-js";
import { styled } from "styled-components";
import { FiDelete } from "react-icons/fi";
import { MdDelete, MdExpand } from "react-icons/md";

import Context from "../Context";
import { FaExpand } from "react-icons/fa";
import { BsZoomIn } from "react-icons/bs";

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Button = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  /* padding: 5px; */
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #cebaa3;
  transition: 0.25s ease-out;

  &:hover {
    transform: scale(0.9);
  }
`;

const Button2 = styled(Button)`
  bottom: unset;
  top: 15px;
`;

export default function LoadImageAndDecrypt({
  imageName,
  style = {},
  deleteButton,
}) {
  const { setForm } = useContext(Context);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    // loadImage();

    let cache = checkCache();

    if (!cache) {
      loadImage().then(decryptImage).then(setImageURL);
    }
  }, []);

  if (!imageURL) return null;

  if (deleteButton) {
    style.cursor = "pointer";
    return (
      <Container>
        <Img onClick={expandView} style={style} src={imageURL} />
        <Button2 onClick={expandView}>
          <BsZoomIn />
        </Button2>
        <Button onClick={deleteButton}>
          <MdDelete />
        </Button>
      </Container>
    );
  }

  return <Img style={style} src={imageURL} />;

  function expandView() {
    setForm({
      component: <LoadImageAndDecrypt imageName={imageName} />,
    });
  }

  function checkCache() {
    if (!window.savedImages) window.savedImages = [];
    if (window.savedImages[imageName]) {
      setImageURL(window.savedImages[imageName]);
      return true;
    }

    return false;
  }

  async function loadImage() {
    let base = process.env.REACT_APP_SERVER;
    let route = base + "/api/v1/cactus-diary/image/?imageName=" + imageName;

    let res = await fetch(route);
    let imageBlob = await res.blob();
    return imageBlob;
  }

  function decryptImage(file) {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = () => {
        //   console.log("Decrypting It");

        //   console.log(reader.result);
        var decrypted = CryptoJS.AES.decrypt(
          reader.result,
          window.intermediateKey
        ); // Decryption: I: Base64 encoded string (OpenSSL-format) -> O: WordArray
        var typedArray = convertWordArrayToUint8Array(decrypted); // Convert: WordArray -> typed array

        var fileDec = new Blob([typedArray]); // Create blob from typed array
        var url = window.URL.createObjectURL(fileDec);

        if (!window.savedImages) window.savedImages = [];
        window.savedImages[imageName] = url;

        resolve(url);
      };
      reader.readAsText(file);
    });
  }
}

function convertWordArrayToUint8Array(wordArray) {
  var arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
  var length = wordArray.hasOwnProperty("sigBytes")
    ? wordArray.sigBytes
    : arrayOfWords.length * 4;
  var uInt8Array = new Uint8Array(length),
    index = 0,
    word,
    i;
  for (i = 0; i < length; i++) {
    word = arrayOfWords[i];
    uInt8Array[index++] = word >> 24;
    uInt8Array[index++] = (word >> 16) & 0xff;
    uInt8Array[index++] = (word >> 8) & 0xff;
    uInt8Array[index++] = word & 0xff;
  }
  return uInt8Array;
}
