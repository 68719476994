let defaultTags = {
  A: { name: "Work", disableEdit: true },
  B: { name: "Love", disableEdit: true },
  C: { name: "Rant", disableEdit: true },
  D: { name: "Family", disableEdit: true },
  E: { name: "Friends", disableEdit: true },
  F: { name: "Learned", disableEdit: true },
  G: { name: "Mistake", disableEdit: true },
  H: { name: "Idea", disableEdit: true },
  I: { name: "Question", disableEdit: true },
};

export default defaultTags;
