import { useContext, useState } from "react";
import styled from "styled-components";
import Context from "../Context";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import CustomButton from "./CustomButton";
import PromptContainer from "./prompt/PromptContainer";
import PromptTitle from "./prompt/PromptTitle";
import PromptInput from "./prompt/PromptInput";
import decrypt from "../controllers/decrypt";
import capitalizeFirstLetter from "../controllers/capitalizeFirstLetter";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export default function ProvideEncryptionKey() {
  const { loggedInUser, setEncryptionKey, setIntermediateKey } =
    useContext(Context);
  const [eKey, setEkey] = useState("");
  const alert = useAlert();
  const navigate = useNavigate();

  return (
    <PromptContainer>
      <PromptTitle>Please! Type the encryption key to gain access</PromptTitle>
      <PromptInput
        onSubmit={verify}
        type="password"
        placeholder="Your Encryption Key"
        value={eKey}
        onChange={({ target }) => setEkey(target.value)}
      />

      <Buttons>
        <CustomButton onClick={verify}>Verify</CustomButton>
        <CustomButton
          onClick={() => {
            navigate("/reset-account");
          }}
        >
          Reset Account
        </CustomButton>
      </Buttons>
    </PromptContainer>
  );

  function verify() {
    let hashedEncryptionKey = loggedInUser.hashedEncryptionKey;
    let newHash = sha256(eKey);
    newHash = newHash.toString(Base64);
    console.log(newHash);

    if (newHash === hashedEncryptionKey) {
      let intermediateKey = decrypt(
        loggedInUser.encryptedIntermediateKey,
        eKey
      );

      setIntermediateKey(intermediateKey);

      setEncryptionKey(eKey);

      alert.show(`Welcome! ${capitalizeFirstLetter(loggedInUser.name)}`);
    } else {
      alert.show("Invalid Encryption Key");
    }
  }
}
