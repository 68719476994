import { useContext, useState } from "react";
import styled from "styled-components";
import Context from "../Context";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import { useAlert } from "react-alert";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";
import CustomButton from "./CustomButton";
import PromptContainer from "./prompt/PromptContainer";
import PromptTitle from "./prompt/PromptTitle";
import PromptInput from "./prompt/PromptInput";

export default function ResetAccount() {
  const [val, setVal] = useState("");
  const [loading, setLoading] = useState(null);

  const alert = useAlert();

  if (loading) return <LoadingSection />;

  return (
    <PromptContainer>
      <PromptTitle>
        Please! type "DELETE EVERYTHING" and press the button to reset the
        account{" "}
      </PromptTitle>
      <PromptInput
        onSubmit={verify}
        placeholder='Type "DELETE EVERYTHING"'
        value={val}
        onChange={({ target }) => setVal(target.value)}
      />
      <CustomButton onClick={verify}>Reset Account</CustomButton>
    </PromptContainer>
  );

  async function verify() {
    if (val.toUpperCase() !== "DELETE EVERYTHING")
      return alert.show("Please type the words exactly");
    setLoading(true);
    await serverLine.post("/reset-account", {
      verification: "DELETE EVERYTHING",
    });
    window.location = "/";
  }
}
