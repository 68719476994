import { $getRoot, $getSelection } from "lexical";
import { useEffect } from "react";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { CodeNode } from "@lexical/code";
import ListMaxIndentLevelPlugin from "./ListMaxIndentLevelPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { styled } from "styled-components";

const theme = {
  // Theme styling goes here
};

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

let defaultState = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
    ],
    direction: null,
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};

function StoreInitalValue({ docID, initialEditorState }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialEditorState) {
      const parsed = editor.parseEditorState(initialEditorState);
      // console.log(JSON.stringify(initialEditorState), parsed);
      editor.setEditorState(parsed);
    } else {
      const parsed = editor.parseEditorState(defaultState);
      // console.log(JSON.stringify(initialEditorState), parsed);
      editor.setEditorState(parsed);
    }
  }, [docID]);

  return null;
}

const Container = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  pointer-events: none;
  top: 15px;
  left: 0;
`;

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

const EDITOR_NODES = [
  AutoLinkNode,
  CodeNode,
  HeadingNode,
  LinkNode,
  ListNode,
  ListItemNode,
  QuoteNode,
];

export function Editor({ initialEditorState, onEditorChange, docID }) {
  const initialConfig = {
    namespace: "MyEditor",
    theme,
    onError,
    nodes: EDITOR_NODES,
  };

  const onChange = (editorState) => {
    editorState.read(() => {
      const json = editorState.toJSON();
      onEditorChange(json);
      console.log(JSON.stringify(json));
    });
  };

  return (
    <Container>
      {" "}
      <LexicalComposer initialConfig={initialConfig}>
        {/* <PlainTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<div>Enter some text...</div>}
        ErrorBoundary={LexicalErrorBoundary}
      /> */}

        <RichTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={<Placeholder>Enter some text...</Placeholder>}
          ErrorBoundary={LexicalErrorBoundary}
        />

        <OnChangePlugin onChange={onChange} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        {/* <ListMaxIndentLevelPlugin maxDepth={7} /> */}
        <ListPlugin />
        <TabIndentationPlugin />
        <StoreInitalValue
          docID={docID}
          initialEditorState={initialEditorState}
        />
        <HistoryPlugin />
        <MyCustomAutoFocusPlugin />
      </LexicalComposer>
    </Container>
  );
}
