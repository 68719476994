import getDayName from "./getDayName";
import getMonthName from "./getMonthName";
import getTagName from "./getTagName";

export default function getItemTextContent({ postData, item, loggedInUser }) {
  let date = new Date(postData.date);
  let txt = `${date.getDate()} ${getMonthName(
    date
  )} ${date.getFullYear()} - ${getDayName(date)}`;

  txt += " " + item.title;
  txt += " " + item.description;
  txt += " " + getTagName({ loggedInUser, tagID: item.tagID });

  return txt.toLowerCase();
}
