import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { serverLine } from "./serverLine";

export default async function loadContent({ type, key, spaceID }) {
  let data = await serverLine.get(
    "/content/?type=" + type + "&spaceID=" + spaceID
  );

  if (!data) return [];
  if (!data.length) return [];

  data.sort(function (b, a) {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateA.getTime() - dateB.getTime();
  });

  let documents = {};

  if (!key) key = "_id";

  for (let item of data) {
    documents[item[key]] = {
      ...item,
      content: item.content,
    };
  }

  return documents;
}
