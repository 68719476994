import { useContext, useState } from "react";
import styled from "styled-components";
import Context from "../Context";
import LoadingSection from "./LoadingSection";
import { serverLine } from "../controllers/serverLine";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import PromptContainer from "./prompt/PromptContainer";
import PromptTitle from "./prompt/PromptTitle";
import PromptSubtitle from "./prompt/PromptSubtitle";
import PromptInput from "./prompt/PromptInput";
import CustomButton from "./CustomButton";
import { useAlert } from "react-alert";
import isNonIsoSymbol from "../controllers/isNonIsoSymbol";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div``;
const SubTitle = styled.div``;
const Input = styled.input``;
const SubmitButton = styled.div``;

export default function CreateEncryptionKey({ changeKey }) {
  const { setEncryptionKey } = useContext(Context);
  const [eKey, setEkey] = useState("");
  const [eKey2, setEkey2] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  if (loading) return <LoadingSection />;

  return (
    <PromptContainer>
      <Container>
        <PromptTitle>Please! Create an encryption key</PromptTitle>
        <PromptSubtitle>
          If you ever lose this key, you will lose all your content forever.
          Encryption key cannot be recovered once lost.
        </PromptSubtitle>
      </Container>
      <PromptInput
        type="password"
        placeholder="New Encryption Key"
        value={eKey}
        onSubmit={save}
        onChange={({ target }) => setEkey(target.value)}
      />
      <PromptInput
        type="password"
        onSubmit={save}
        placeholder="Confirm Encryption Key"
        value={eKey2}
        onChange={({ target }) => setEkey2(target.value)}
      />
      <CustomButton onClick={save}>Save</CustomButton>
    </PromptContainer>
  );

  async function save() {
    if (!eKey) return;

    if (isNonIsoSymbol(eKey)) {
      return alert.show("Non ISO Symbols are not allowed");
    }

    if (eKey.length < 8)
      return alert.show("Encryption key should be atleast 8 letters");

    if (eKey !== eKey2)
      return alert.show(
        "Please make sure you have typed the same password in both the fields"
      );

    setLoading(true);
    setEncryptionKey(eKey);
    // let hashedEncryptionKey = sha256(eKey);
    // hashedEncryptionKey = hashedEncryptionKey.toString(Base64);

    if (changeKey) {
      await serverLine.post("/change-encryption-key", {
        newEncryptionKey: eKey,
      });
    } else {
      await serverLine.post("/encryption-key", {
        encryptionKey: eKey,
      });
    }

    window.location.href = window.location.origin;
    // updateLoggedInUser();
  }
}
