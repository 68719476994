import styled from "styled-components";
import { useContext, useEffect, useState } from "react";

import { useAlert } from "react-alert";
import Context from "../Context";
import LoadingSection from "./LoadingSection";
import { serverLine } from "../controllers/serverLine";
import { useSearchParams } from "react-router-dom";

const Button = styled.button`
  border: 1px solid;
  background: transparent;
  display: flex;
  flex-direction: row;
  color: var(--color);
  gap: 10px;
  align-items: center;
  padding: 14px 20px;
  cursor: pointer;
  border-radius: 25px;
`;

const ErrorBox = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
`;

function SendAuthCode() {
  const [searchParams] = useSearchParams();
  let { dispatch } = useContext(Context);
  let [error, setError] = useState(false);

  useEffect(() => {
    console.log("req sending auth code");
    sendCode();
  }, []);

  if (error) return <ErrorBox>{error}</ErrorBox>;
  return <LoadingSection />;

  function done(data) {
    localStorage.setItem("cactus-diary-user-id", data.userID);
    localStorage.setItem("cactus-diary-user-token", data.token);

    dispatch({ type: "UPDATE", field: "loggedIn", value: true });
    window.location = "/";
  }

  function sendCode() {
    console.log("sending auth code");
    let location = window.location.origin + window.location.pathname;
    let code = searchParams.get("code");

    serverLine
      .post("/shared/google-auth-code", { code, location })
      .then(done)
      .catch(({ message }) => {
        if (typeof message == "object") message = JSON.stringify(message);
        setError(message);
      });
  }
}

export default SendAuthCode;
