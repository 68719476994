import { forwardRef, useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import DiaryPost from "../DiaryPost";
import getDateWithSlash from "../../controllers/getDateWithSlash";
import { serverLine } from "../../controllers/serverLine";
import LoadingSection from "../LoadingSection";
import encrypt from "../../controllers/encrypt";
import encryptAndUpload from "../../controllers/uploadContent";
import PromptInput from "../prompt/PromptInput";
import CustomButton from "../CustomButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CustomMasonry from "../CustomMasonry";
import loadContent from "../../controllers/loadContent";
import selectFile from "../../controllers/selectFile";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";
import uploadContent from "../../controllers/uploadContent";
import selectFileAndCheck from "../../controllers/selectFileAndCheck";
import uploadFile from "../../controllers/uploadFile";
import {
  BsCalendar,
  BsFileImage,
  BsFillSendFill,
  BsImage,
  BsImages,
  BsPen,
  BsPencil,
  BsSave,
  BsSearch,
  BsSend,
  BsTag,
  BsTagFill,
} from "react-icons/bs";
import { AiOutlineFileImage, AiOutlineSave } from "react-icons/ai";
import TagAdder from "../TagAdder";
import getTagName from "../../controllers/getTagName";
import getPostTextContent from "../../controllers/getPostTextContent";
import defaultTags from "../../data/defaultTags";
import onChangeStop from "../../controllers/onChangeStop";
import DatePicker from "react-datepicker";
import DateSelector from "../DateSelector";
import getDateString from "../../controllers/getDateString";
import { MdViewCompact } from "react-icons/md";
import { useAlert } from "react-alert";
import WriteDiary from "../WriteDiary";
import { TbIroningSteam, TbListDetails } from "react-icons/tb";
import { FaFeather, FaSearch } from "react-icons/fa";
import { FiFeather, FiSearch } from "react-icons/fi";
import rankDiaryPosts from "../../controllers/rankDiaryPosts";

const Container = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 35px;
`;

const MainContent = styled.div`
  display: flex;

  flex-direction: column;
  gap: 25px;
  margin-bottom: 300px;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreateDiaryButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color);
  color: var(--bgColor);
  padding: 0 35px;
  height: 37px;
  /* width: 200px; */

  @media (max-width: 900px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const SelectViewType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
  gap: 0;
  padding: 0 0;
  border-radius: 0;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid var(--translucentHard);
`;

const ViewType = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  height: 37px;
  padding: 0 20px;
  position: relative;

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }

  position: relative;

  ${({ isActive }) => {
    if (isActive)
      return `
      background:var(--translucent);

    `;
  }}
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FilterLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: space-between;
  }
`;

const FilterRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const FilterTagIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterTagText = styled.div`
  display: flex;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
`;

const FilterTag = styled.div`
  border: 1px solid var(--translucentHard);
  padding: 0 15px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  font-size: 15px;

  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }
`;

const SearchContainer = styled.div`
  /* background: var(--translucent); */
  padding: 0 15px;
  border: 1px solid var(--translucentHard);
  border-radius: 10px;
  height: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  gap: 5px;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const SearchBox = styled.input`
  border: none;
  height: 37px;
  font-size: 15px;

  background: transparent;
  color: var(--color);

  @media (max-width: 900px) {
    flex: 1;
  }
`;
const SearchButton = styled.div`
  height: 30px;
  width: 30px;
  font-size: 15px;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }
`;

export default function Diary() {
  const {
    encryptionKey,
    setForm,
    loggedInUser,
    spaceID,
    diaryDocs,
    setDiaryDocs,
  } = useContext(Context);

  const [loading, setLoading] = useState(true);

  const [postToHide, setPostToHide] = useState([]);
  const [filterTagID, setFilterTagID] = useState(null);
  let [filterSearchQuery, setFilterSearchQuery] = useState("");
  const [tmpSearchQuery, setTmpSearchQuery] = useState("");

  const [viewType, setViewType] = useState("COMPACT");

  let data = diaryDocs;
  let setData = setDiaryDocs;

  let filterTagName = getTagName({ tagID: filterTagID, loggedInUser });
  filterTagName = filterTagName.toLowerCase();
  filterSearchQuery = filterSearchQuery.toLowerCase();

  useEffect(() => {
    let newPostToHide = [];

    if (data) {
      for (let key in data) {
        let postData = data[key];
        let txt = getPostTextContent({ loggedInUser, postData });

        // console.log(txt, filterTagName, filterSearchQuery);

        if (filterTagName) {
          if (txt.indexOf(filterTagName.toLowerCase()) === -1) {
            newPostToHide.push(postData._id);
          }
        }

        if (filterSearchQuery) {
          if (txt.indexOf(filterSearchQuery.toLowerCase()) === -1) {
            if (!newPostToHide.includes(postData._id))
              newPostToHide.push(postData._id);
          }
        }
      }

      setPostToHide(newPostToHide);
    }
  }, [filterTagName, filterSearchQuery]);

  useEffect(() => {
    if (encryptionKey) {
      serverLine
        .get("/content/?type=DIARY&spaceID=" + spaceID)
        .then((newData) => {
          let processed = rankDiaryPosts(newData);
          setData(processed);
          setLoading(false);
        });
    }
  }, [encryptionKey, spaceID]);

  if (loading) return <LoadingSection />;

  let createButton = (
    <CreateDiaryButton onClick={openDiaryWriter}>
      <ButtonIcon>
        <FiFeather />
      </ButtonIcon>

      <ButtonText>Write</ButtonText>
    </CreateDiaryButton>
  );

  let filterTagBox = (
    <FilterTag onClick={openTagAdderForFilterSelect}>
      <FilterTagIcon>
        <BsTag />
      </FilterTagIcon>
      <FilterTagText>{filterTagName ? filterTagName : "Tag"}</FilterTagText>
    </FilterTag>
  );

  let selectViewBox = (
    <SelectViewType>
      <ViewType
        isActive={viewType == "COMPACT"}
        onClick={() => {
          setViewType("COMPACT");
        }}
      >
        <TbListDetails />
      </ViewType>
      <ViewType
        isActive={viewType == "IMAGE"}
        onClick={() => {
          setViewType("IMAGE");
        }}
      >
        <BsImages />
      </ViewType>
    </SelectViewType>
  );

  let seachBoxVar = (
    <SearchContainer>
      <SearchButton
        onClick={() => {
          setFilterSearchQuery(tmpSearchQuery);
        }}
      >
        <FiSearch />
      </SearchButton>
      <SearchBox
        placeholder="Search Here"
        value={tmpSearchQuery}
        onKeyUp={(e) => {
          // console.log(e.key);
          if (e.key === "Enter") {
            setFilterSearchQuery(e.target.value);
          }
        }}
        onChange={({ target }) => {
          setTmpSearchQuery(target.value);
          onChangeStop({
            callback: () => {
              setFilterSearchQuery(target.value);
            },
            name: "search",
          });
        }}
      />
    </SearchContainer>
  );

  let isMobile = window.innerWidth <= 900;

  return (
    <Container>
      <MainContent>
        <FilterSection>
          <FilterLeft>
            {!isMobile ? [seachBoxVar, filterTagBox, selectViewBox] : null}
            {isMobile ? [filterTagBox, selectViewBox, createButton] : null}
          </FilterLeft>

          <FilterRight>
            {!isMobile ? createButton : null}
            {isMobile ? seachBoxVar : null}
          </FilterRight>
        </FilterSection>
        <CustomMasonry maxCol={3} list={renderContent()} />
      </MainContent>
    </Container>
  );

  function openTagAdderForFilterSelect() {
    setForm({
      title: "Select Tag",
      component: (
        <TagAdder
          oldTagID={filterTagID}
          callback={(newTagID) => {
            setFilterTagID(newTagID);
            setForm(null);
          }}
        />
      ),
    });
  }

  function openDiaryWriter() {
    let settings = {};

    if (window.innerWidth < 900) {
      settings = { height: "100vh", width: "100vw" };
    }

    setForm({
      title: "Write Diary",
      settings: settings,
      component: <WriteDiary />,
    });
  }

  function renderContent() {
    let toReturn = [];

    if (!data) return [];

    for (let contentID in data) {
      let post = data[contentID];
      if (!includesImage(post) && viewType == "IMAGE") continue;
      if (postToHide.includes(post._id)) continue;

      toReturn.push(
        <DiaryPost
          viewType={viewType}
          key={contentID}
          filterTagName={filterTagName}
          filterSearchQuery={filterSearchQuery}
          data={data}
          setData={setData}
          postData={post}
        />
      );
    }

    return toReturn;
  }

  function includesImage(item) {
    let ans = false;

    if (!item.content) return ans;
    for (let con of item.content) {
      if (con.images) {
        if (con.images.length) return true;
      }
    }

    return ans;
  }
}
