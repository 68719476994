import { serverLine } from "./serverLine";

function logout() {
  localStorage.removeItem("cactus-diary-user-token");
  localStorage.removeItem("cactus-diary-user-id");

  var parent = window.parent;

  if (parent) {
    parent.postMessage("LOGOUT", "*");
  }

  window.location = "/";

  // serverLine.delete("/cookie").then(() => {
  //   window.location = window.location.origin;
  // });
}

export default logout;
