import getMonthName from "../controllers/getMonthName";

import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import onChangeStop from "../controllers/onChangeStop";
import { serverLine } from "../controllers/serverLine";
import encrypt from "../controllers/encrypt";
import Context from "../Context";
import LoadImageAndDecrypt from "./LoadImageAndDecrypt";
import getDateWithSlash from "../controllers/getDateWithSlash";
import ItemBox from "./ItemBox";
import getDayName from "../controllers/getDayName";
import getTagName from "../controllers/getTagName";
import getItemTextContent from "../controllers/getItemTextContent";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* border-radius: 10px; */
  /* background: var(--translucent); */
`;
const Title = styled.div`
  padding: 5px 15px;
  font-size: 15px;
  border-radius: 5px;
  opacity: 0.5;
  background: var(--translucent);
  font-weight: 500;
`;

export default function DiaryPost({
  postData,
  data,
  setData,
  filterTagName,
  viewType,
  filterSearchQuery,
}) {
  const { loggedInUser } = useContext(Context);
  const [indexToHide, setIndexToHide] = useState([]);

  useEffect(() => {
    let newIndexToHide = [];

    if (postData.content) {
      for (let index = 0; index < postData.content.length; index++) {
        let txt = getItemTextContent({
          loggedInUser,
          postData,
          item: postData.content[index],
          filterTagName,
          filterSearchQuery,
        });

        console.log(
          postData.date,
          txt,
          filterTagName,
          filterSearchQuery,
          txt.indexOf(filterTagName),
          txt.indexOf(filterSearchQuery)
        );

        if (filterTagName) {
          if (txt.indexOf(filterTagName) === -1) {
            newIndexToHide.push(index);
            console.log("Hiding for tag" + index);
          }
        }

        if (filterSearchQuery) {
          if (txt.indexOf(filterSearchQuery) === -1) {
            if (!newIndexToHide.includes(index)) {
              console.log("Hiding for search" + index);
              newIndexToHide.push(index);
            }
          }
        }
      }
    }

    setIndexToHide(newIndexToHide);
  }, [filterTagName, filterSearchQuery]);

  let date = new Date(postData.date);
  let theDate = `${date.getDate()} ${getMonthName(
    date
  )} ${date.getFullYear()} ・ ${getDayName(date)}`;

  useEffect(() => {}, []);

  // return theDate + "- " + JSON.stringify(indexToHide) + postData.content.length;

  return (
    <Container>
      <Title>{theDate}</Title>
      {getContent()}
    </Container>
  );

  function getContent() {
    let toReturn = [];
    if (!postData.content) return null;

    for (let index = 0; index < postData.content.length; index++) {
      if (indexToHide.includes(index)) {
        console.log("removing index" + index);
        continue;
      }
      toReturn.push(
        <ItemBox
          viewType={viewType}
          postData={postData}
          data={data}
          setData={setData}
          key={index}
          index={index}
        />
      );
    }

    // toReturn = toReturn.reverse();
    return toReturn;
  }
}
