import { useState } from "react";
import { useAlert } from "react-alert";
import { styled } from "styled-components";
import { serverLine } from "../controllers/serverLine";
import CustomInput from "./CustomInput";
import LoadingSection from "./LoadingSection";
import isNonIsoSymbol from "../controllers/isNonIsoSymbol";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const Input = styled.input``;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
  /* width: 100%; */
  /* margin-bottom: 25px; */
  gap: 0;
  padding: 0 0;
  border-radius: 0;
  border-radius: 10px;
  border: 1px solid var(--translucentHard);
`;
const Tab = styled.div`
  display: flex;

  align-items: center;
  border-radius: 10px;
  font-size: 17px;
  cursor: pointer;
  /* background: var(--translucent); */

  /* width: calc(62vw / 7); */
  flex: 1;
  justify-content: center;
  height: 40px;

  padding: 0 25px;
  position: relative;

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }

  position: relative;

  ${({ isActive }) => {
    if (isActive)
      return `

    background:var(--translucent);

  `;
  }}
`;

const SubmitButton = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function LoginOrSignupWithoutEmail() {
  const [mode, setMode] = useState("LOGIN");
  const [username, setUsername] = useState("");
  const [encKey1, setEncKey1] = useState("");
  const [encKey2, setEncKey2] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  if (loading) return <LoadingSection />;

  let form = {
    SIGN_UP: (
      <Form>
        <CustomInput
          onSubmit={signUp}
          value={username}
          placeholder="Type Username"
          onChange={updateField(setUsername)}
        />
        <CustomInput
          onSubmit={signUp}
          type="password"
          value={encKey1}
          placeholder="Encryption Key"
          onChange={updateField(setEncKey1)}
        />
        <CustomInput
          onSubmit={signUp}
          type="password"
          value={encKey2}
          placeholder="Confirm Encryption Key"
          onChange={updateField(setEncKey2)}
        />
        <SubmitButton onClick={signUp}>Submit</SubmitButton>
      </Form>
    ),
    LOGIN: (
      <Form>
        <CustomInput
          onSubmit={login}
          value={username}
          placeholder="Type Username"
          onChange={updateField(setUsername)}
        />
        <CustomInput
          onSubmit={login}
          type="password"
          value={encKey1}
          placeholder="Encryption Key"
          onChange={updateField(setEncKey1)}
        />
        <SubmitButton onClick={login}>Submit</SubmitButton>
      </Form>
    ),
  };

  return (
    <Container>
      <Tabs>
        <Tab isActive={mode == "LOGIN"} onClick={updateTab("LOGIN")}>
          Login
        </Tab>
        <Tab isActive={mode == "SIGN_UP"} onClick={updateTab("SIGN_UP")}>
          Sign Up
        </Tab>
      </Tabs>

      {form[mode]}
    </Container>
  );

  function updateField(callback) {
    return ({ target }) => {
      callback(target.value);
    };
  }

  async function login() {
    if (!username) return alert.show("Please fill the username field");
    if (!encKey1) return alert.show("Please fill the encryption field");

    if (isNonIsoSymbol(encKey1)) {
      return alert.show("Non ISO Symbols are not allowed");
    }

    setLoading(true);

    try {
      let newData = await serverLine.post("/login-without-email", {
        username,
        encryptionKey: encKey1,
      });
      localStorage.setItem("cactus-diary-user-id", newData.userID);
      localStorage.setItem("cactus-diary-user-token", newData.token);
      window.location.reload();
    } catch ({ message }) {
      alert.show(message);
      setLoading(false);
    }

    setLoading(false);
  }

  async function signUp() {
    if (!username) return alert.show("Please fill the username field");

    if (isNonIsoSymbol(encKey1)) {
      return alert.show("Non ISO Symbols are not allowed");
    }

    if (!encKey1 || !encKey2)
      return alert.show("Please fill the encryption field");
    if (encKey1.length < 8)
      return alert.show("Encryption field should be 8 letters");
    if (encKey1 !== encKey2)
      return alert.show("Encryption fields are not matching");

    setLoading(true);

    try {
      let newData = await serverLine.post("/signup-without-email", {
        username,
        encryptionKey: encKey1,
      });
      localStorage.setItem("cactus-diary-user-id", newData.userID);
      localStorage.setItem("cactus-diary-user-token", newData.token);
      window.location.reload();
    } catch ({ message }) {
      alert.show(message);
      setLoading(false);
    }

    setLoading(false);
  }

  function updateTab(tabVal) {
    return () => {
      setMode(tabVal);
    };
  }
}
