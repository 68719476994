import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import DiaryPost from "../DiaryPost";
import getDateWithSlash from "../../controllers/getDateWithSlash";
import { serverLine } from "../../controllers/serverLine";
import LoadingSection from "../LoadingSection";
import encrypt from "../../controllers/encrypt";
import encryptAndUpload from "../../controllers/uploadContent";
import PromptInput from "../prompt/PromptInput";
import CustomButton from "../CustomButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CustomMasonry from "../CustomMasonry";
import loadContent from "../../controllers/loadContent";
import selectFile from "../../controllers/selectFile";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";
import uploadContent from "../../controllers/uploadContent";
import selectFileAndCheck from "../../controllers/selectFileAndCheck";
import uploadFile from "../../controllers/uploadFile";
import RichTextEditor from "../RichTextEditor";
import onChangeStop from "../../controllers/onChangeStop";
import { FiDelete } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

const Container = styled.div`
  display: flex;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    flex-direction: row;
  }
`;

const Tab = styled.button`
  width: 10vw;
  padding: 15px;
  cursor: pointer;
  border-radius: 15px;
  color: var(--color);
  background: var(--translucent);
  border: none;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }

  ${({ highlight }) => {
    if (highlight)
      return `
      color:var(--bgColor);
      background:var(--color);
    
    `;
  }}

  @media (max-width: 700px) {
    width: auto;
  }
`;

const EmptyNote = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 50px 0;
  align-items: center;
  border-radius: 15px;
  background: var(--translucent);
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 15px;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Notes() {
  const { encryptionKey, spaceID } = useContext(Context);
  const [selectedTab, setSelectedTab] = useState(null);
  const [tmpText, setTmpText] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (encryptionKey) loadContent({ type: "NOTE", spaceID }).then(setData);
  }, [encryptionKey, spaceID]);

  if (loading || !data) return <LoadingSection />;

  // console.log(data);

  return (
    <Container>
      <Tabs>{renderTabs()}</Tabs>

      <Column>
        {selectedTab ? (
          <>
            <RichTextEditor
              docID={selectedTab}
              onChange={updateValue}
              value={data[selectedTab].content}
            />
            <Buttons>
              <Button onClick={deleteNote}>
                <MdDelete />
              </Button>
            </Buttons>
          </>
        ) : (
          <EmptyNote>Select a Note or Create a note</EmptyNote>
        )}
      </Column>
    </Container>
  );

  function deleteNote() {
    let newData = { ...data };
    delete newData[selectedTab];
    setSelectedTab(null);
    setData(newData);
    serverLine.post("/content", {
      docID: selectedTab,
      action: "DELETE",
    });
  }

  function renderTabs() {
    let tabs = [<Tab onClick={addDocument}>+ Document</Tab>];

    for (let docID in data) {
      let doc = data[docID];
      let content = doc.content;
      if (!content) content = { title: "Untitled" };

      tabs.push(
        <Tab
          highlight={docID === selectedTab}
          onClick={() => {
            setSelectedTab(docID);
          }}
        >
          {content.title}
        </Tab>
      );
    }
    return tabs;
  }

  function updateValue(newValue) {
    let newData = { ...data };
    newData[selectedTab].content = newValue;
    setData(newData);

    onChangeStop({
      callback: () => {
        serverLine.post("/content", { docID: selectedTab, content: newValue });
      },
      name: selectedTab,
    });
  }

  async function addDocument() {
    setLoading(true);
    let newPost = await serverLine.post("/content", {
      content: null,
      date: getDateWithSlash(new Date()),
      spaceID,
      type: "NOTE",
    });

    let newData = { ...data };
    newData[newPost._id] = { content: null, _id: newPost._id };
    setData(newData);
    setSelectedTab(newPost._id);
    setLoading(false);
  }
}
