import defaultSpaces from "../data/defaultSpaces";

export default function getSpaceName({
  spaceID,
  loggedInUser,
  defaultValue = "",
  prefix = "",
}) {
  if (!spaceID) return defaultValue;

  let allSpaces = { ...defaultSpaces };

  if (!loggedInUser) return "";

  if (loggedInUser.spaces) {
    allSpaces = { ...allSpaces, ...loggedInUser.spaces };
  }
  if (allSpaces[spaceID]) {
    return prefix + allSpaces[spaceID].name;
  }
}
