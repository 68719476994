import "./App.css";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import Boilerplate from "./components/Boilerplate";
import Home from "./components/Home";
import Settings from "./components/Settings";
import SendAuthCode from "./components/SendAuthCode";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AlertTemplate from "./components/AlertTemplate";
import Notes from "./components/tabs/Notes";
import Gallery from "./components/tabs/Gallery";
import ResetAccount from "./components/ResetAccount";
import Diary from "./components/tabs/Diary";
import ChangeEncryptionKey from "./components/ChangeEncryptionKey";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 3000,
  containerStyle: {
    zIndex: 900,
    pointerEvents: "unset",
  },
  offset: "30px",
  transition: transitions.SCALE,
};

// if (window.innerWidth < 900) options.position = positions.TOP_CENTER;

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Boilerplate>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="auth-redirect" element={<SendAuthCode />} />
              <Route path="settings" element={<Settings />} />
              <Route path="reset-account" element={<ResetAccount />} />
              <Route path="notes" element={<Notes />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="change-key" element={<ChangeEncryptionKey />} />

              {/* <Route path="local-notes" element={<OfflineNotes />} /> */}
              <Route path="diary" element={<Diary />} />
              <Route path="*" element={"404"} />
            </Route>
          </Routes>
        </Boilerplate>
      </BrowserRouter>
    </AlertProvider>
  );
}

export default App;
