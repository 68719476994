import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext } from "react";
import Context from "../../Context";
import { FaGooglePlay } from "react-icons/fa";
import LogoSVG from "../LogoSVG";
import BrandContainer from "../BrandContainer";
import { VscCode, VscExtensions } from "react-icons/vsc";
import LoginOrSignupWithoutEmail from "../LoginOrSignupWithoutEmail";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: cover;

  flex-direction: row;
  gap: 100px;
  align-items: center;
  /* justify-content: space-between; */

  /* @media (max-width: 1200px) {
    flex-direction: column;
  } */

  @media (max-width: 700px) {
    background-image: unset;
    align-items: center;
    overflow-y: auto;
    flex-direction: column;
    /* padding: 25px; */
    /* align-items: flex-start; */
  }
`;

const TheImage = styled.img`
  width: 38vw;
  height: 100vh;
  object-fit: cover;

  @media (max-width: 700px) {
    /* width: 90vw; */
    display: none;
  }
`;

const Container = styled.div`
  width: 62vw;
  margin: 50px 0;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 100px;

  @media (max-width: 700px) {
    width: 100vw;
    margin: 0;
    /* align-items: center; */
    padding: 25px;
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-family: "Playfair Display", serif;

  font-size: 35px;
  /* background: linear-gradient(to bottom, var(--color), var(--color), #000); */
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-background-clip: text; */

  width: 50vw;
  @media (max-width: 900px) {
    width: 100%;
    font-size: 8vw;
  }
`;

const MainButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;

  align-items: center;
  border: none;

  width: 100%;

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: 25px;
    left: 0;
    background: var(--bgColor);
    box-shadow: -15px -13px 50px 16px var(--bgColor);
  }
`;

const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid;
  gap: 25px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 300px;
  }
`;

const BigButton = styled(Button)`
  width: auto;
  padding: 0 50px;
  flex: unset;
  border: none;
  height: 60px;
  box-shadow: 3px 5px 8px 0px #00000066;

  @media (max-width: 700px) {
    width: calc(100vw - 50px);
  }
`;

const DownloadAppButton = styled(Button)`
  @media (min-width: 950px) {
    display: none;
  }
`;

const H1 = styled.h1`
  font-weight: 900;
  margin-top: 0;
  font-size: 24px;
  width: auto;
  width: 100%;
  margin: 0;

  @media (min-width: 900px) {
    font-size: 15px;
  }
`;
const Medium = styled.div`
  font-size: 18px;
  opacity: 0.8;
  font-weight: 400;
  width: 50vw;
  line-height: 28px;

  @media (max-width: 1200px) {
    width: 50vw;
    font-size: 18px;
  }

  @media (max-width: 900px) {
    width: 100%;
    /* font-size: 15px; */
    /* line-height: unset; */
  }

  /* @media (max-width: 400px) {
    display: none;
  } */
`;

const Words = styled.div`
  margin-top: 0;
  display: flex;
  margin-top: 0;
  gap: 120px;

  flex-direction: column;

  @media (max-width: 900px) {
    gap: 50px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 19px;

  @media (max-width: 900px) {
    text-align: center;
    margin-bottom: 200px;
  }
`;

//A devlog/studylog/artlog Platform

const Icon = styled.img`
  height: 18px;
`;

const MoreButton = styled(Button)`
  width: 60px;
  height: 60px;
  font-size: 21px;
  background-color: var(--bgColor);
  color: var(--color);
  flex: unset;
  opacity: 0.7;

  @media (max-width: 900px) {
    position: fixed;
    top: 15px;
    right: 10px;
    border: none;
  }
`;

const HeroDrawing = styled.img`
  height: 150px;
  width: auto;
  object-fit: contain;
  @media (min-width: 900px) {
    display: none;
  }

  ${({ colorMode }) => {
    if (colorMode == "LIGHT") return `filter: invert(1) contrast(100);`;
  }}
`;

export default function LoggedOutHomePage() {
  let { setForm, colorMode } = useContext(Context);

  let date = new Date();

  // return (
  //   <>
  //     {date.getDate()} / {date.getMonth() + 1} / {date.getFullYear()}
  //   </>
  // );

  return (
    <ParentContainer>
      <TheImage src="/background2.jpg" />
      <Container>
        <Words>
          <BrandContainer />

          <TextContainer>
            <HeroDrawing colorMode={colorMode} src="/heroDrawing.png" />
            <Tagline>Encrypted Diary & Note Taking App.</Tagline>
            <Medium>
              Everyone needs a place where they can be honest with themselves.
              Everything you type or upload is encrypted. So, not even our
              employees can see what you have typed or what image you have
              uploaded.
            </Medium>
          </TextContainer>
        </Words>

        <MainButtons>
          <BigButton onClick={goToAuthScreen}>
            <GrGoogle />
            Login With Google
          </BigButton>

          <BigButton onClick={openLoginWithoutEmail}>
            Login Without Email
          </BigButton>

          <MoreButton onClick={openMoreMenu} style={{}}>
            <HiMenuAlt4 />
          </MoreButton>
        </MainButtons>
      </Container>
    </ParentContainer>
  );

  function openApp() {
    // window.location = playstore link
  }

  function openLoginWithoutEmail() {
    setForm({ title: "", component: <LoginOrSignupWithoutEmail /> });
  }

  function open(link) {
    return () => {
      return (window.location = link);
    };
  }

  function openMoreMenu() {
    setForm({
      options: [
        {
          name: "About us",
          onClick: open("https://arnav.upon.one"),
        },
      ],
    });
  }
}
